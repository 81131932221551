export default appdata => {
  appdata.parameters = {
    version: null,
    phone:{
      maxRingtoneLength: 10,
      maxStandByLength: 60,
    }
  }

  return appdata
}
