import { defineStore } from 'pinia'

export let useProfileStore = defineStore('profile', {
  state: () => ({
    settings: null,

    profile: null,
    userData: null,
  })
})
