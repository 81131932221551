import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"
import { useAppStore } from '@/stores/appdata'

let db = getFirestore()
let collectionName = 'profiles/'

function docToInstance(document) {
  let data = document.data()
  return data ? new Profile(
    document.id, data.email, data.firstname, data.lastname, data.avatar, data.color,
    data.role, data.dev, data.commercial, data.banned, data.activated, data.currentOrganisation, data.currentApps, data.organisations, data.appdata,
    data.created_at, data.activated_at, data.last_login_at, data.last_pwChange_at
  ) : null
}

export default class Profile {
  constructor(id, email, firstname, lastname, avatar, color, role, dev, commercial, banned, activated, currentOrganisation, currentApps, organisations, appdata, created_at, activated_at, last_login_at, last_pwChange_at) {
    this.id = id
    this.email = email
    this.firstname = firstname
    this.lastname = lastname
    this.avatar = avatar
    this.color = color
    this.role = role
    this.dev = dev
    this.commercial = commercial
    this.banned = banned
    this.activated = activated
    this.currentOrganisation = currentOrganisation
    this.currentApps = currentApps
    this.organisations = organisations
    this.appdata = appdata

    this.created_at = created_at
    this.activated_at = activated_at
    this.last_login_at = last_login_at
    this.last_pwChange_at = last_pwChange_at
  }

  static listenById(id, callback) {
    return onSnapshot(doc(db, collectionName, id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }
  static listenAll(callback) {
    let appStore = useAppStore()
    let ids = appStore.app.users

    if (ids.length === 0) {
      callback([])
    } else {
      let docsQuery = query(collection(db, collectionName), where(documentId(), 'in', ids))
      onSnapshot(docsQuery, snapshot => {
        let list = []
        snapshot.forEach(document => {
          list.push(docToInstance(document))
        })
        callback(list)
      })
    }
  }
  static listenByRole(role, callback) {
    let appStore = useAppStore()
    let ids = appStore.app.users

    if (ids.length === 0) {
      callback([])
    } else {
      let docsQuery = query(collection(db, collectionName), where(documentId(), 'in', ids), where('role', '==', role))
      onSnapshot(docsQuery, snapshot => {
        let list = []
        snapshot.forEach(document => {
          list.push(docToInstance(document))
        })
        callback(list)
      })
    }
  }
  async saveCurrentApp() {
    await setDoc(doc(db, collectionName, this.id), {
      currentApps: this.currentApps
    })
  }

  static async getById(id) {
    let document = await getDoc(doc(db, collectionName, id))
    return docToInstance(document)
  }
}
