import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"
import getCollectionPath from '@/assets/functions/getCollectionPath'

const db = getFirestore()
const collectionName = 'userData/'

function docToInstance(doc) {
  const data = doc.data()
  return data ? new UserData(doc.id, data.user, data.activities, data.notifs, data.lastCheck) : null
}

export default class UserData {
  constructor(id, user, activities, notifs, lastCheck) {
    this.id = id
    this.user = user
    this.activities = activities
    this.notifs = notifs
    this.lastCheck = lastCheck
  }

  static initOne(user){
    let tmp_activities = {
      phone: [],
      mail: [],
      files: [],
      agenda: [],
      tasks: [],
    }

    let data = new UserData(null, user, tmp_activities, [], 0)
    return data
  }
    
  static async getNewId() {
    const newDocRef = doc(collection(db, await getCollectionPath(collectionName)))
    return newDocRef.id
  }

  async save() {
    const new_data = {
      user: this.user,
      activities: this.activities,
      notifs: this.notifs,
      lastCheck: this.lastCheck
    }

    if (this.id) {
      await setDoc(doc(db, await getCollectionPath(collectionName), this.id), new_data)
    } else {
      await addDoc(collection(db, await getCollectionPath(collectionName)), new_data)
    }
  }

  static async listenById(id, callback) {
    return onSnapshot(doc(db, await getCollectionPath(collectionName), id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }

  static async listenByUser(userId, callback) {
    return onSnapshot(query(collection(db, await getCollectionPath(collectionName)), where("user", "==", userId)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list)
    })
  }

  static async listenAll(callback) {
    onSnapshot(collection(db, await getCollectionPath(collectionName)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list)
    })
  }
  static async getById(id) {
    let document = await getDoc(doc(db, collectionName, id))
    return docToInstance(document)
  }

  static async getByUser(userId,){
    let response = await getDocs(query(collection(db, await getCollectionPath(collectionName)), where("user", "==", userId)))
    let list = []
    response.forEach(doc => {
        list.push(docToInstance(doc))
    })
    return list
  }

  static async getAll(){
    let response = await getDocs(collection(db, await getCollectionPath(collectionName)))
    let list = []
    response.forEach(doc => {
        list.push(docToInstance(doc))
    })
    return list
}

  async delete(){
      let documentRef = doc(db, await getCollectionPath(collectionName), this.id)
      deleteDoc(documentRef)
  }

}
